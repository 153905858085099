import React, { useEffect } from 'react';

const STRIPE_PRICE_TABLE_ID = "prctbl_1QUtEsE6gL8DFM4jScdjUQvl";
const STRIPE_PUBLIC_KEY = "pk_test_51QUoawE6gL8DFM4junyM2ky8Uz3D6MTCULEPASZpco5UlgLHwWa8lI0ugqHEhTM1lDGIglZku7cGxksbqtEmIANP00dYLLWVLL";

const PricingTable = ({ client_secret }) => {

  return (
    <div className='pricing-table-container'>
      <div className='pricing-table-wrapper'>
        <div style={{ width: '100%' }}>
          <h1 className="title">Choose Your Package</h1>
          <div className='content'>Choose your new package. </div>
          <stripe-pricing-table
            pricing-table-id={ STRIPE_PRICE_TABLE_ID }
            publishable-key={ STRIPE_PUBLIC_KEY }
            customer-session-client-secret={ client_secret }
          >
          </stripe-pricing-table>
        </div>
      </div>
    </div>
  );
};

export default PricingTable;