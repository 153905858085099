import * as React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { history } from "./store/history";
import {
  ForgotPasswordPage,
  LoginPage,
  RegisterPage,
  Error400,
  Error401,
  Error403,
  Error404,
  Error500,
  Error503,
  Empty,
  Email,
  ProfilePage,
  VerifyEmailPage,
  ResetPasswordPage,
  EmptyPage,

  Measure, 
  ViewMeasure, 
  Products, 
  EditProduct, 
  ViewProduct, 
  Categories, 
  EditCategory, 
  Tags, 
  EditTag, 
  Users,
  UserEdit, 
  MyAccount
} from "./pages";

import FormElementsPage from "./FormElementsPage.react";
import PricingCardsPage from "./interface/PricingCardsPage.react";
import CardsDesignPage from "./interface/CardsDesignPage.react";
import StoreCardsPage from "./components/StoreCardsPage.react.js";
import IconPage from "./components/IconPage.react.js";
import ChartsPage from "./interface/ChartsPage.react";
import GalleryPage from "./GalleryPage.react";
import MapCardsPage from "./components/MapCardsPage.react";
import BlogPage from "./components/BlogPage.react";
import { PrivateRoute } from "./PrivateRoute";
import "tabler-react/dist/Tabler.css";

function App(props) {
  return (
    // <React.StrictMode>
    <Router history={history}>
      <Switch>


        <PrivateRoute exact path='/myaccount' component={MyAccount} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/accounts' component={EmptyPage} roles={["Super", "Admin", "Dealer"]} />
        <Route exact path='/verify-email' component={VerifyEmailPage} />
        <PrivateRoute exact path='/400' component={Error400} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/401' component={Error401} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/403' component={Error403} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/404' component={Error404} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/500' component={Error500} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/503' component={Error503} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/blog' component={BlogPage} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/cards' component={CardsDesignPage} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/charts' component={ChartsPage} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/email' component={Email} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/empty-page' component={Empty} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/form-elements' component={FormElementsPage} roles={["Super", "Admin", "Dealer"]} />
        <Route exact path='/forgot-password' component={ForgotPasswordPage} />
        <Route exact path='/reset-password/:id' component={ResetPasswordPage} />
        <PrivateRoute exact path='/gallery' component={GalleryPage} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/icons' component={IconPage} roles={["Super", "Admin", "Dealer"]} />
        <Route exact path='/login' component={LoginPage} />
        <PrivateRoute exact path='/maps' component={MapCardsPage} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/pricing-cards' component={PricingCardsPage} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/profile' component={ProfilePage} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/store' component={StoreCardsPage} roles={["Super", "Admin", "Dealer"]} />
        {/* <Route exact path='/register' component={RegisterPage} /> */}
        <PrivateRoute exact path='/400' component={Error400} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/401' component={Error401} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/403' component={Error403} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/404' component={Error404} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/500' component={Error500} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/503' component={Error503} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/blog' component={BlogPage} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/cards' component={CardsDesignPage} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/charts' component={ChartsPage} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/email' component={Email} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/empty-page' component={Empty} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/form-elements' component={FormElementsPage} roles={["Super", "Admin", "Dealer"]} />
        <Route exact path='/forgot-password' component={ForgotPasswordPage} />
        <Route exact path='/reset-password/:id' component={ResetPasswordPage} />
        <Route exact path='/verify-email' component={VerifyEmailPage} />
        <PrivateRoute exact path='/gallery' component={GalleryPage} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/icons' component={IconPage} roles={["Super", "Admin", "Dealer"]} />
        <Route exact path='/login' component={LoginPage} />
        <PrivateRoute exact path='/maps' component={MapCardsPage} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/pricing-cards' component={PricingCardsPage} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/profile' component={ProfilePage} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/store' component={StoreCardsPage} roles={["Super", "Admin", "Dealer"]} />
        <Route exact path='/register' component={RegisterPage} />

        <PrivateRoute exact path='/' component={ Measure } roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/measure' component={ Measure } roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/measure/:id' component={ ViewMeasure } roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/products' component={ Products } roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/products/:id' component={ EditProduct } roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/products/view/:id' component={ ViewProduct } roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/categories' component={ Categories } roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/categories/:id' component={ EditCategory } roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/tags' component={ Tags } roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/tags/:id' component={ EditTag } roles={["Super", "Admin"]} />
        <PrivateRoute exact path='/users' component={Users} roles={["Super", "Admin", "Dealer"]} />
        <PrivateRoute exact path='/users/:id' component={UserEdit} roles={["Super", "Admin", "Dealer"]} />
        
        {/* <PrivateRoute exact path='/' component={EmptyPage} roles={["Super", "Admin", "Dealer"]} /> */}

        <Route component={Error404} />
      </Switch>
    </Router>
    // </React.StrictMode>
  );
}

export default App;
